import React, { useState } from 'react';
import { Card, Tabs, Typography, Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const Privacidade = () => {
  const [currentLang, setCurrentLang] = useState('en');

  const renderContent = (content) => {
    if (typeof content === 'string') {
      return content;
    }
    return content;
  };

  const termsContent = {
    pt: {
      title: 'Política de Privacidade',
      content: [
        {
          text: [
            'Cada uma das partes se compromete a não divulgar a qualquer terceiro, quaisquer informações, inclusive o Conteúdo do CLIENTE e outras informações prestadas pelo CLIENTE à CONTRATADA, obtidas de outra parte, que sejam designadas como proprietárias ou confidenciais ou que, pela sua natureza, seja manifestamente claro que devam ser consideradas confidenciais ou proprietárias, ressalvado que a CONTRATADA poderá acessar, divulgar e compartilhar essas Informações Confidenciais do CLIENTE e de seus Usuários Finais na extensão necessária para a prestação dos serviços hora contratados. Cada uma das partes concorda que deverá usar o mesmo grau de zelo que utiliza para a proteção de suas próprias informações confidenciais de natureza similar para proteger o sigilo e evitar a divulgação ou uso das Informações Confidenciais, visando impedir que se tornem de domínio público ou da posse de terceiros, que não as pessoas autorizadas nos termos deste Contrato a possuir quaisquer destas informações. A obrigação de confidencialidade nesta cláusula não se aplica às informações que o recipiente possa demonstrar que tenham se tornado do seu conhecimento de outra forma, que não fornecidas nos termos deste Contrato ou obtidas da outra parte, e às informações que sejam de domínio público. Além disso, as informações confidenciais poderão ser divulgadas na medida em que a divulgação seja necessária para permitir o cumprimento por parte da legislação aplicável, de uma decisão judicial ou para o cumprimento de exigências das agências governamentais ou de terceiros, cuja divulgação seja determinada pela referida parte, mas somente após ter primeiramente notificado a outra parte da exigência de divulgação, salvo se esta notificação for proibida.',
            'A CONTRATADA coleta as informações fornecidas pelos usuários para a realização dos cadastros em seus servidores. Os dados coletados são as informações inerentes a empresa e usuários, que serão usados estritamente para efeito de registros em seus servidores e emissão de contrato.'
          ]
        },
        {
          section: 'Marco Civil da Internet (lei nº 12.965/2014)',
          text: [
            'A CONTRATADA assegura que está de acordo com o Marco Civil da Internet (lei nº 12.965/2014) e que removerá qualquer material caso seja devidamente notificada de que os materiais infringiram direitos autorais de terceiros. Além disso, a CONTRATADA pode, em circunstâncias apropriadas, encerrar as contas de infratores de direitos autorais. Se você acredita que seu trabalho foi copiado de uma forma que constitua violação de direitos autorais, por favor nos forneça um aviso por escrito, contendo as seguintes informações: Seu nome, endereço, número de telefone e endereço de e-mail (se houver). A descrição do perfil com direitos autorais que você alega ter sido violado. Uma descrição de onde o material que você alega estar infringindo está localizado no material fornecido pela equipe do SOFTWARE, suficiente para que possamos localizar o supostamente violador. Uma declaração de que você tem uma crença de boa fé que o uso do trabalho com direitos autorais não é autorizado pelo proprietário dos direitos autorais, seu agente ou a lei. Uma declaração sua de que as informações em seu aviso são precisas e sob pena de perjúrio, que você é o proprietário dos direitos autorais ou autorizado a agir em nome do proprietário dos direitos autorais. Uma assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos autorais. Você pode enviar essas informações via: (1) e-mail, enviando uma notificação para atendimento@proabakus.com.br ou (2) Correios para agente de Direitos Autorais regulamentados.'
          ]
        },
        {
          section: 'Dados',
          text: [
            'Os dados referentes a uso do SOFTWARE ficam armazenados em servidores com uso da tecnologia de nuvem (cloud computer), desde que o cliente não esteja utilizando a versão Stand Alone.'
          ]
        },
        {
          section: 'Restrições de Responsabilidades',
          text: [
            'Por meio deste, o CLIENTE reconhece que o SOFTWARE pode não estar disponível ou ser indisponibilizado por diversos fatores, incluindo, sem limitação, manutenções periódicas do sistema (programadas ou não), manifestações da natureza, falhas técnicas do software, infraestrutura de telecomunicações ou atraso ou interrupção ocasionada por vírus, ataques de negação de serviços, aumento ou flutuação de demanda, ações e omissões de terceiros ou qualquer outra causa que esteja fora do controle da CONTRATADA. Portanto, a CONTRATADA se isenta expressamente de qualquer garantia explícita ou implícita a respeito de disponibilidade, acessibilidade ou desempenho do sistema e/ou SOFTWARE',
            'O cliente assume o custo total de qualquer dano ocasionado pelo próprio uso do SOFTWARE, pelas informações contidas ou compiladas pelo software e pela interação (ou imperícia na interação) com qualquer outro hardware ou software, seja fornecido pela CONTRATADA ou por terceiros. Pela máxima extensão permitida pela lei aplicável, em nenhuma circunstância a CONTRATADA, nem seus fornecedores ou licenciadores serão responsabilizados por qualquer dano (incluindo, sem limitação, danos por lucros cessantes, interrupção de negócios, perda de informações comerciais, perda de bens intangíveis, interrupção de trabalho, ruptura, dano ou falha no hardware ou software, custos de reparo, perda de tempo de trabalho ou outras perdas pecuniárias) decorrente do uso ou da imperícia no uso do SOFTWARE, ou incompatibilidade do SOFTWARE com qualquer hardware, software ou tipo de utilização.',
            'Mesmo que, por segurança e conveniência, a CONTRATADA mantenha sob sua guarda durante a vigência deste contrato cópias de seguranças (backups) dos dados cadastrados pelo cliente no SOFTWARE, é dever do cliente fazer suas próprias cópias, de modo que a contratada em hipótese alguma será responsável pela perda de dados cadastrados.',
            'Em nenhum caso, alguma das partes será responsável em relação à outra parte pelo lucro cessante, ou pelos danos indiretos, especiais, incidentais, consequentes, de cobertura ou punitivos, seja qual for a causa, ora de natureza contratual, ora por ato ilícito, ou conforme qualquer outra teoria de responsabilidade, tenha sido notificada ou não a outra parte sobre a possibilidade de tais danos. A isenção precedente não será aplicada quando proibido pela lei aplicável.'
          ]
        },
        {
          section: 'Exclusão de Dados',
          text: [
            <>
              1. Excluir a Conta do Usuário: Os usuários podem excluir a conta e
              os dados associados diretamente por meio do botão "Excluir Conta"
              disponível no Menu "Perfil". Para realizar esta ação, é necessário
              fazer o login na plataforma e seguir as instruções para confirmar
              a exclusão, {<Link to="/cliente">clicando aqui</Link>} para logar.
            </>,
            <>
              2. Solicitação de Exclusão por Contato Direto: Caso o usuário
              tenha dificuldades ou precise de assistência adicional, ele pode
              solicitar a exclusão de seus dados pessoais entrando em contato
              com nossa equipe de suporte por meio do seguinte endereço de
              e-mail:{' '}
              <a href="mailto:atendimento@easyassist.com.br?subject=Remoção Conta eadelivery">
                atendimento@easyassist.com.br
              </a>
              . Responderemos a todas as solicitações de exclusão dentro de um
              prazo razoável, conforme exigido pela legislação aplicável.
            </>,
            'Observação: Após a solicitação de exclusão, todos os dados pessoais armazenados serão removidos de nossos sistemas, salvo exceções previstas em lei.'
          ]
        }
      ]
    },
    en: {
      title: 'Privacy Policy',
      content: [
        {
          text: [
            'Each party agrees not to disclose to any third party any information, including the CLIENT’s Content and other information provided by the CLIENT to the CONTRACTOR, obtained from the other party, that is designated as proprietary or confidential or that, by its nature, is clearly intended to be considered confidential or proprietary. However, the CONTRACTOR may access, disclose, and share these Confidential Information of the CLIENT and its End Users to the extent necessary for the provision of the contracted services. Each party agrees to use the same degree of care it uses to protect its own similar confidential information to safeguard the secrecy of and prevent the disclosure or use of Confidential Information, aiming to prevent it from becoming public knowledge or in the possession of unauthorized third parties, except for those authorized in accordance with this Agreement to possess any such information. The confidentiality obligation in this clause does not apply to information the recipient can demonstrate has become known to them by other means, other than provided under this Agreement or obtained from the other party, or to information that is in the public domain. Additionally, Confidential Information may be disclosed to the extent necessary to comply with applicable law, a court order, or the requirements of governmental agencies or third parties, where disclosure is required by said parties, but only after notifying the other party of the disclosure requirement, unless such notice is prohibited.',
            'The CONTRACTOR collects information provided by users for registration purposes on its servers. The data collected includes information related to the company and users, which will be used strictly for registration in its servers and contract issuance.'
          ]
        },
        {
          section: 'Internet Civil Framework (Law No. 12,965/2014)',
          text: [
            'The CONTRACTOR ensures compliance with the Internet Civil Framework (Law No. 12,965/2014) and will remove any material if duly notified that it infringes third-party copyrights. Moreover, the CONTRACTOR may, in appropriate circumstances, terminate the accounts of copyright infringers. If you believe that your work has been copied in a way that constitutes a copyright infringement, please provide us with a written notice containing the following information: Your name, address, phone number, and email address (if any). A description of the copyrighted profile you claim has been infringed. A description of where the infringing material is located within the material provided by the SOFTWARE team, sufficient for us to locate the alleged infringement. A statement that you have a good faith belief that the use of the copyrighted work is not authorized by the copyright owner, its agent, or the law. A statement that the information in your notice is accurate and under penalty of perjury, that you are the copyright owner or authorized to act on behalf of the copyright owner. An electronic or physical signature of the person authorized to act on behalf of the copyright owner. You may submit this information via: (1) email, sending a notification to atendimento@proabakus.com.br or (2) by mail to the regulated Copyright Agent.'
          ]
        },
        {
          section: 'Data',
          text: [
            'Data related to the use of the SOFTWARE is stored on servers using cloud technology (cloud computing), provided the client is not using the Stand Alone version.'
          ]
        },
        {
          section: 'Liability Limitations',
          text: [
            'By this, the CLIENT acknowledges that the SOFTWARE may not be available or may be unavailable due to various factors, including but not limited to periodic system maintenance (scheduled or unscheduled), natural events, technical failures of the software, telecommunications infrastructure, or delays or interruptions caused by viruses, denial-of-service attacks, increased or fluctuating demand, actions and omissions by third parties, or any other cause outside the CONTRACTOR’s control. Therefore, the CONTRACTOR expressly disclaims any explicit or implicit warranty regarding the availability, accessibility, or performance of the system and/or SOFTWARE.',
            'The client assumes full responsibility for any damage caused by their own use of the SOFTWARE, by the information contained or compiled by the software, and by interactions (or lack of skill in interactions) with any other hardware or software, whether provided by the CONTRACTOR or third parties. To the maximum extent permitted by applicable law, under no circumstances shall the CONTRACTOR, nor its suppliers or licensors, be liable for any damage (including but not limited to lost profits, business interruptions, loss of commercial information, loss of intangible assets, work disruption, breakdown, or failure of hardware or software, repair costs, loss of work time, or other monetary losses) arising from the use or misuse of the SOFTWARE, or from incompatibility of the SOFTWARE with any hardware, software, or usage type.',
            'Even though, for safety and convenience reasons, the CONTRACTOR may retain backup copies of the data registered by the client in the SOFTWARE during the term of this agreement, it is the client’s responsibility to make their own copies, so that the CONTRACTOR shall not be responsible under any circumstances for the loss of registered data.',
            'Under no circumstances will either party be liable to the other for lost profits or for indirect, special, incidental, consequential, cover, or punitive damages, regardless of the cause, whether contractual, tortious, or based on any other theory of liability, whether or not the other party has been notified of the possibility of such damages. The foregoing exclusion will not apply where prohibited by applicable law.'
          ]
        },
        ,
        {
          section: '4. Account Deletion',
          text: [
            '1. Users have the right to request the deletion of their personal data at any time. To ensure that all data is removed from the system, users may request the deletion of their data through one of the following methods:',
            <>
              2. Delete User Account: Users can delete their account and
              associated data directly by using the “Delete Account” button
              available in the “Profile” menu. To perform this action, users
              must log into the platform and follow the instructions to confirm
              the deletion, {<Link to="/cliente">by clicking </Link>} to log in.
            </>,
            <>
              Request Deletion via Direct Contact: If the user encounters
              difficulties or needs additional assistance, they can request the
              deletion of their personal data by contacting our support team via
              the following email address:{' '}
              <a href="mailto:atendimento@easyassist.com.br?subject=Remoção Conta eadelivery">
                atendimento@easyassist.com.br
              </a>
              . We will respond to all deletion requests within a reasonable
              time, as required by applicable law.'
            </>,
            'Note: After a deletion request is made, all personal data stored will be removed from our systems, except in cases where exceptions are required by law.'
          ]
        }
      ]
    }
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '1500px',
    margin: '0 auto'
  };

  const contentStyle = {
    padding: '24px',
    height: '41.875rem',
    overflowY: 'auto'
  };

  const paragraphStyle = {
    textAlign: 'justify',
    marginBottom: '16px'
  };

  const handleLanguageChange = (lang) => {
    setCurrentLang(lang);
  };

  return (
    <Layout style={{ minHeight: '99vh', padding: '24px' }}>
      <Content>
        <Card style={cardStyle}>
          <Title
            level={2}
            style={{ textAlign: 'center', marginBottom: '24px' }}
          >
            {termsContent[currentLang].title}
          </Title>

          <Tabs
            defaultActiveKey="en"
            type="card"
            onChange={handleLanguageChange}
          >
            <TabPane tab="English" key="en">
              <div style={contentStyle}>
                {termsContent.en.content.map((section, index) => (
                  <div key={index} style={{ marginBottom: '24px' }}>
                    <Title level={4}>{section.section}</Title>
                    {section.text.map((paragraph, pIndex) => (
                      <Paragraph key={pIndex} style={paragraphStyle}>
                        {renderContent(paragraph)}
                      </Paragraph>
                    ))}
                  </div>
                ))}
              </div>
            </TabPane>

            <TabPane tab="Português" key="pt">
              <div style={contentStyle}>
                {termsContent.pt.content.map((section, index) => (
                  <div key={index} style={{ marginBottom: '24px' }}>
                    <Title level={4}>{section.section}</Title>
                    {section.text.map((paragraph, pIndex) => (
                      <Paragraph key={pIndex} style={paragraphStyle}>
                        {renderContent(paragraph)}
                      </Paragraph>
                    ))}
                  </div>
                ))}
              </div>
            </TabPane>
          </Tabs>
        </Card>
      </Content>
    </Layout>
  );
};

export default Privacidade;
