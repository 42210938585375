import React, { useState } from 'react';
import { Card, Tabs, Typography, Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const Termos = () => {
  const [currentLang, setCurrentLang] = useState('en');

  const renderContent = (content) => {
    if (typeof content === 'string') {
      return content;
    }
    return content;
  };

  const termsContent = {
    pt: {
      title: 'Termos de Uso',
      content: [
        {
          section: '1. Aceitação dos Termos',
          text: [
            'Este Contrato de Licença de Usuário Final é um acordo legal entre usuário pessoa física ou pessoa jurídica, doravante denominado "CLIENTE", e PROABAKUS DESENVOLVIMENTO DE SISTEMAS LTDA, inscrita no CNPJ sob número 13.107.496/0001-49, com sede na Av. José Amora Sá, 1201 Sala 01, Distrito Industrial II, Eusébio-CE, CEP 61760-000, Brasil, denominada "CONTRATADA", para uso do programa de computador denominado "EADELIVERY" (doravante denominado "SOFTWARE"), disponibilizado neste ato pela PROABAKUS.',
            'Ao utilizar o SOFTWARE, mesmo que parcialmente ou a título de teste, o CLIENTE estará vinculado aos termos deste CONTRATO, concordando com os mesmos, principalmente CONSENTINDO COM O ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do CLIENTE para a integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância dos termos de uso aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida.'
          ]
        },
        {
          section: '2. Propriedade Intelectual',
          text: [
            'O presente contrato não concede ao CLIENTE nenhum direito de propriedade intelectual sobre o SOFTWARE ou quaisquer partes dele ou dos sistemas agregados, ficando certo que esses direitos são e permanecerão sendo propriedade autoral exclusiva da CONTRATADA, detentora absoluta dos direitos morais e patrimoniais inerentes. Também constitui propriedade exclusiva da CONTRATADA, ou está devidamente licenciado para ela, todo o conteúdo disponibilizado no site, incluindo, mas não se limitando a textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, marcas, patentes, softwares e qualquer outro material. O CLIENTE concorda em não modificar, criar obras derivadas, descompilar ou de outra forma tentar extrair o código fonte, copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o SOFTWARE objeto deste CONTRATO, assim como seus módulos, partes, manuais ou quaisquer informações relativas ao mesmo; Através deste contrato, é cedido ao CLIENTE apenas o direito de uso do SOFTWARE em questão, sem a necessidade de apresentar ou fornecer o código fonte ou estrutura interna do produto.'
          ]
        },
        {
          section: '3. Licenciamento de Uso do Software',
          text: [
            'Sujeito aos termos e condições do presente instrumento, este CONTRATO concede ao CLIENTE uma licença revogável, não exclusiva e intransferível, para usar o SOFTWARE. É concedido ao CLIENTE o direito de uso do SOFTWARE de forma gratuita por período e prazo indeterminado, até que o CLIENTE ou a CONTRATADA realize SEM prévio aviso a exclusão do cadastro do CLIENTE no SOFTWARE.',
            'O SOFTWARE é fornecido "como está" sem nenhuma outra garantia. O cliente assume todos os riscos e responsabilidades pela escolha do software para alcançar os resultados desejados, bem como pela utilização e resultados obtidos por meio do SOFTWARE. A CONTRATADA não garante que o software esteja isento de erros, possíveis interrupções ou falhas, ou que o mesmo seja compatível com qualquer hardware ou software específico. Pela extensão máxima permitida pela lei aplicável, a empresa se isenta de todas as garantias, sejam explícitas ou implícitas, incluindo, sem limitação, garantias implícitas de adequação comercial, adequação para um propósito específico e não-violação em relação ao SOFTWARE e aos materiais escritos que o acompanham.'
          ]
        },
        {
          section: '4. Exclusão de Dados',
          text: [
            <>
              1. Excluir a Conta do Usuário: Os usuários podem excluir a conta e
              os dados associados diretamente por meio do botão "Excluir Conta"
              disponível no Menu "Perfil". Para realizar esta ação, é necessário
              fazer o login na plataforma e seguir as instruções para confirmar
              a exclusão, {<Link to="/cliente">clicando aqui</Link>} para logar.
            </>,
            <>
              2. Solicitação de Exclusão por Contato Direto: Caso o usuário
              tenha dificuldades ou precise de assistência adicional, ele pode
              solicitar a exclusão de seus dados pessoais entrando em contato
              com nossa equipe de suporte por meio do seguinte endereço de
              e-mail:{' '}
              <a href="mailto:atendimento@easyassist.com.br?subject=Remoção Conta eadelivery">
                atendimento@easyassist.com.br
              </a>
              . Responderemos a todas as solicitações de exclusão dentro de um
              prazo razoável, conforme exigido pela legislação aplicável.
            </>,
            'Observação: Após a solicitação de exclusão, todos os dados pessoais armazenados serão removidos de nossos sistemas, salvo exceções previstas em lei.'
          ]
        }
      ]
    },
    en: {
      title: 'Terms of Use',
      content: [
        {
          section: '1. Acceptance of Terms',
          text: [
            <>
              his End User License Agreement is a legal agreement between the
              user, whether an individual or a legal entity, hereinafter
              referred to as "CLIENT", and PROABAKUS DESENVOLVIMENTO DE SISTEMAS
              LTDA, a company registered under the CNPJ number
              13.107.496/0001-49, headquartered at Av. José Amora Sá, 1201 Sala
              01, Distrito Industrial II, Eusébio-CE, CEP 61760-000, Brazil,
              hereinafter referred to as "CONTRACTOR", for the use of the
              software program named "EADELIVERY" (hereinafter referred to as
              "SOFTWARE"), provided by PROABAKUS through its website and mobile
              app stores, for a period determined by the CLIENT at the time of
              licensing the SOFTWARE, which includes the computer program and
              may also include associated physical media, any printed materials,
              and any "online" or electronic documentation.
            </>,
            "By using the SOFTWARE, even partially or for testing purposes, the CLIENT agrees to be bound by the terms of this AGREEMENT, consenting to the access, collection, use, storage, processing, and protection techniques for the CLIENT's information for the full execution of the SOFTWARE's functionalities. In case of disagreement with the terms presented here, the use of the SOFTWARE must be immediately discontinued."
          ]
        },
        {
          section: '2. Intellectual Property',
          text: [
            'This agreement does not grant the CLIENT any intellectual property rights over the SOFTWARE or any parts of it, or the integrated systems, and it is understood that these rights are and will remain the exclusive property of the CONTRACTOR, who holds all the moral and patrimonial rights inherent to the SOFTWARE. All content made available on the website, including but not limited to texts, graphics, images, logos, icons, photographs, editorial content, notifications, trademarks, patents, software, and any other material, is also the exclusive property of the CONTRACTOR or is properly licensed to it. The CLIENT agrees not to modify, create derivative works, decompile, or otherwise attempt to extract the source code, copy, assign, sublicense, sell, lease, or provide as collateral, reproduce, donate, alienate, transfer in any way, whether in full or in part, free of charge or for a fee, temporarily or permanently, the SOFTWARE subject to this AGREEMENT, as well as its modules, parts, manuals, or any related information. This agreement grants the CLIENT only the right to use the SOFTWARE, without the need to present or provide the source code or internal structure of the product.'
          ]
        },
        {
          section: '3. Software Usage License',
          text: [
            "Subject to the terms and conditions of this agreement, this CONTRACT grants the CLIENT a revocable, non-exclusive, and non-transferable license to use the SOFTWARE. The CLIENT is granted the right to use the SOFTWARE free of charge for an indefinite period, until the CLIENT or the CONTRACTOR removes the CLIENT's registration from the SOFTWARE, without prior notice.",
            <>
              The SOFTWARE is provided "as is" without any other warranty. The
              CLIENT assumes all risks and responsibilities for choosing the
              SOFTWARE to achieve the desired results, as well as for the use
              and results obtained through the SOFTWARE. The CONTRACTOR does not
              guarantee that the SOFTWARE is free of errors, interruptions, or
              failures, nor that it is compatible with any specific hardware or
              software. To the maximum extent permitted by applicable law, the
              company disclaims all warranties, express or implied, including
              but not limited to implied warranties of merchantability, fitness
              for a particular purpose, and non-infringement in relation to the
              SOFTWARE and the accompanying written materials.
            </>
          ]
        },
        {
          section: '4. Account Deletion',
          text: [
            '1. Users have the right to request the deletion of their personal data at any time. To ensure that all data is removed from the system, users may request the deletion of their data through one of the following methods:',
            <>
              2. Delete User Account: Users can delete their account and
              associated data directly by using the “Delete Account” button
              available in the “Profile” menu. To perform this action, users
              must log into the platform and follow the instructions to confirm
              the deletion, {<Link to="/cliente">by clicking </Link>} to log in.
            </>,
            <>
              Request Deletion via Direct Contact: If the user encounters
              difficulties or needs additional assistance, they can request the
              deletion of their personal data by contacting our support team via
              the following email address:{' '}
              <a href="mailto:atendimento@easyassist.com.br?subject=Remoção Conta eadelivery">
                atendimento@easyassist.com.br
              </a>
              . We will respond to all deletion requests within a reasonable
              time, as required by applicable law.'
            </>,
            'Note: After a deletion request is made, all personal data stored will be removed from our systems, except in cases where exceptions are required by law.'
          ]
        }
      ]
    }
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '1500px',
    margin: '0 auto'
  };

  const contentStyle = {
    padding: '24px',
    height: '41.875rem',
    overflowY: 'auto'
  };

  const paragraphStyle = {
    textAlign: 'justify',
    marginBottom: '16px'
  };

  const handleLanguageChange = (lang) => {
    setCurrentLang(lang);
  };

  return (
    <Layout style={{ minHeight: '99vh', padding: '24px' }}>
      <Content>
        <Card style={cardStyle}>
          <Title
            level={2}
            style={{ textAlign: 'center', marginBottom: '24px' }}
          >
            {termsContent[currentLang].title}
          </Title>

          <Tabs
            defaultActiveKey="en"
            type="card"
            onChange={handleLanguageChange}
          >
            <TabPane tab="English" key="en">
              <div style={contentStyle}>
                {termsContent.en.content.map((section, index) => (
                  <div key={index} style={{ marginBottom: '24px' }}>
                    <Title level={4}>{section.section}</Title>
                    {section.text.map((paragraph, pIndex) => (
                      <Paragraph key={pIndex} style={paragraphStyle}>
                        {renderContent(paragraph)}
                      </Paragraph>
                    ))}
                  </div>
                ))}
              </div>
            </TabPane>

            <TabPane tab="Português" key="pt">
              <div style={contentStyle}>
                {termsContent.pt.content.map((section, index) => (
                  <div key={index} style={{ marginBottom: '24px' }}>
                    <Title level={4}>{section.section}</Title>
                    {section.text.map((paragraph, pIndex) => (
                      <Paragraph key={pIndex} style={paragraphStyle}>
                        {renderContent(paragraph)}
                      </Paragraph>
                    ))}
                  </div>
                ))}
              </div>
            </TabPane>
          </Tabs>
        </Card>
      </Content>
    </Layout>
  );
};

export default Termos;
